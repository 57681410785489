import React from 'react'
import { Layout, Section, SEO, Content, Title, Footer } from 'components'

function NotFoundPage({ ...props }) {
  return (
    <Layout>
      <SEO title="Stopuligheden.dk" />
      <Section gradient full>
        <Content>
          <Title as="h1" html={`<strong>404</strong><br />Siden findes ikke`} />
        </Content>
      </Section>
      <Footer />
    </Layout>
  )
}

export default NotFoundPage
